import { gql } from 'apollo-boost'
export const SEND_FORM = gql`
	mutation createForm(
		$formName: String!
		$to: String
		$type: String
		$locale: String
		$subject: String
		$name: String
		$firstName: String
		$lastName: String
		$email: String
		$phone: String
		$company: String
		$abn: String
		$serialNo: String
		$message: String
		$address: AddressInput
	) {
		createForm(
			formName: $formName
			to: $to
			type: $type
			locale: $locale
			subject: $subject
			name: $name
			firstName: $firstName
			lastName: $lastName
			email: $email
			phone: $phone
			company: $company
			abn: $abn
			serialNo: $serialNo
			message: $message
			address: $address
		) {
			_id
			formName
			to
			type
			locale
			subject
			name
			firstName
			lastName
			email
			phone
			company
			abn
			serialNo
			message
			address {
				fullAddress
				line1
				city
				state
				postalCode
				country
			}
		}
	}
`
